<template>
    <div class="col-4">
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <v-col cols="12" lg="12" md="12" xs="12" class="d-flex flex-row float-right">
                    <v-text-field
                        :label="$t('message.filterResults')"
                        :value="searchTerm"
                        autocomplete="off"
                        class="mt-2 mb-0 force-text-left"
                        prepend-inner-icon="mdi-filter-outline"
                        @change="searchTerm = $event"
                    />
                    <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults">{{ $t('message.filter') }}</v-btn>
                    <v-btn class="ml-3" @click="searchTerm = null"><v-icon>refresh</v-icon></v-btn>
                    <ExportTable
                        :has-add-function="true"
                        :cols-to-delete="colsToDelete"
                        class="mt-0 mr-0"
                        file-name="thickness"
                        table-id="thicknessTable"
                        style="padding-top: 2px !important;"
                        @add-action="openCreateThicknessDialog()"
                    />
                    <HelpPopUpV2 help-page-component="ThicknessListing" :no-padding="true"></HelpPopUpV2>
                </v-col>
            </v-row>
        </v-sheet>
        <div style="position: relative">
            <v-overlay
                :value="loading.thickness"
                absolute
                opacity="0.15"
            >
                <v-row>
                    <v-col class="text-center">
                        <v-progress-circular
                            color="primary"
                            indeterminate
                            size="40"
                            width="6"
                        />
                    </v-col>
                </v-row>
            </v-overlay>
            <v-data-table
                :footer-props="{
                    itemsPerPageOptions: [20,50,-1],
                    showCurrentPage: true,
                    showFirstLastPage: true
                }"
                :headers="headers"
                :height="tableHeight"
                :items="allThickness"
                :search="searchTerm"
                :options.sync="tableOptions"
                calculate-widths
                class="mt-0 appic-table-light specification-table"
                dense
                id="thicknessTable"
                item-key="Thickness.id"
            >
                <template v-slot:item.index="{ item }">
                    <div class="text-center">{{ allThickness.indexOf(item) + 1}}</div>
                </template>
                <template v-slot:item.Thickness.title="{ item }">
                    <span class="font-weight-bold">{{ item.Thickness.title }}</span>
                </template>
                <template v-slot:item.Thickness.ms="{ item }">
                    <span v-if="item.Thickness.ms == 1">{{ $t('message.metric')  }}</span>
                    <span v-else>{{ $t('message.imperial')  }}</span>
                </template>
                <template v-slot:item.Thickness.group_id="{ item }">
                    <span>{{ getGroup(item.Thickness.group_id) }}</span>
                </template>
                <template v-slot:item.Thickness.id="{ item }">
                    <div class="text-center">
                        <v-menu>
                            <template v-slot:activator="{ on: menu }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on: tooltip }">
                                        <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                            <v-icon>more_vert</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('message.moreActions') }}</span>
                                </v-tooltip>
                            </template>
                            <v-list dense>
                                <v-list-item class="font-sm" @click="openEditThicknessDialog(item.Thickness.id, item.Thickness.title)">
                                    <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateThickness') }}
                                </v-list-item>
                                <v-list-item class="font-sm" @click="deleteThickness(item.Thickness.id, item.Thickness.title)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.deleteThickness') }}</v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>
            </v-data-table>
        </div>
        <AddThickness
            :thickness-id="selectedThicknessId"
            :thickness-name="selectedThicknessName"
            :dialog.sync="editDialog"
            :update-mode="updateMode"
            @dialog-closed="editDialogClosed"
            @update-done="thicknessUpdated"
            @create-done="thicknessCreated"
        />
    </div>
</template>

<script>
// import {mapFields} from "vuex-map-fields";
import {mapActions, mapGetters} from "vuex";

const AddThickness = () => import("Components/Appic/AddThickness");
const ExportTable = () => import("Components/Appic/ExportTable");
const HelpPopUpV2 = () => import("Components/Appic/Help/HelpPopUpV2");

export default {
    name: "ThicknessListing",
    components: { AddThickness, ExportTable, HelpPopUpV2 },
    props: ['addDialog'],
    data() {
        return {
            colsToDelete: {
                start_col: 0,
                ncols: 1
            },
            dialogs: {
                error: false,
                error_message: ""
            },
            editDialog: null,
            hideDefaultFooter: true,
            loading: {
                fetch: false,
                filterResults: false,
                thickness: false
            },
            searchField: null,
            searchTerm: null,
            selectedThicknessId: null,
            selectedThicknessName: null,
            tableHeight: '500',
            tableOptions: {
                page: 1
            },
            totalThickness: 0,
            updateMode: false
        }
    },
    computed: {
        ...mapGetters('thickness', {
            allThickness: 'allThickness'
        }),
        ...mapGetters(['thicknessGroups']),
        headers () {
            return [
                {
                    id: 1,
                    text: this.$t('message.actions'),
                    value: 'Thickness.id',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: false
                },
                {
                    id: 2,
                    text: this.$t('message.thickness'),
                    value: 'Thickness.title',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 2,
                    text: this.$t('message.suffix'),
                    value: 'Thickness.suffix',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 3,
                    text: this.$t('message.system'),
                    value: 'Thickness.ms',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                }
            ]
        }
    },
    methods: {
        ...mapActions('thickness',{
            deleteThicknessById: 'deleteThicknessById',
            getAllThickness: 'getAllThickness',
            resetState: 'resetState'
        }),
        async deleteThickness(val, title) {
            if(await this.$root.$confirm(this.$t('message.deleteThickness') + ' ' +  title, this.$t('message.confirmations.continueThicknessCancelAction'), {color: 'orange'})){
                this.deleteThicknessById(val)
                    .then((response) => {
                        if(response.status == 'done'){
                            this.$toast.success(this.$t('message.successes.thicknessDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.resetState()
                            this.getAllThickness()
                        } else {
                            this.$toast.error(this.$t('message.errors.thicknessNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.thicknessNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            }
        },
        editDialogClosed () {
            this.editDialog = false;
            if(this.updateMode == false){
                this.$emit('add-dialog-closed')
            } else {
                this.updateMode = false
                this.selectedThicknessId = null
                this.selectedThicknessName = null
            }
        },
        filterResults () {
            this.loading.filterResults = true
            let _this = this
            setTimeout(function(){
                _this.loading.filterResults = false
            },700)
        },
        getGroup(groupId) {
            let groupObj = this.thicknessGroups.find(tg => tg.value == groupId)
            if(groupObj){
                return groupObj.text
            }
            return ''
        },
        handleResize() {
            this.tableHeight = window.innerHeight - (285);
        },
        openCreateThicknessDialog () {
            this.updateMode = false
            this.editDialog = true
        },
        openEditThicknessDialog (thicknessId, thicknessName) {
            this.selectedThicknessId = thicknessId
            this.selectedThicknessName = thicknessName
            this.updateMode = true
            this.editDialog = true
        },
        thicknessCreated () {
            this.editDialog = false;
            this.resetState()
            this.$emit('reload')
        },
        thicknessUpdated () {
            this.editDialog = false;
            this.resetState()
            this.$emit('reload')
        },
    },
    watch: {
        addDialog(value) {
            if(value) {
                this.openCreateThicknessDialog ()
            }
        }
    },
    created(){
        this.resetState()
        if(this.allThickness.length == 0) {
            this.loading.thickness = true
            this.getAllThickness()
                .then(() => {
                    this.loading.thickness = false
                })
                .catch(() => {
                    this.loading.thickness = false
                })
        }
    },
    mounted() {
        this.handleResize()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style scoped>

</style>